import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faRss,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faSpotify,
  faTelegram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import './styles.css'

const MobileNav = () => {
  const [toggle, setToggle] = useState(false)

  return (
    <div className="mobile-nav">
      <div className="mobile-nav-container">
        <button onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <FontAwesomeIcon icon={faTimes} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </div>

      <div
        className="mobile-nav-menu"
        style={{ display: toggle ? 'flex' : 'none' }}
      >
        <ul>
          <li>
            <Link to="/pt" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/pt/tags" activeClassName="active">
              Tags
            </Link>
          </li>
          <li>
            <Link to="/pt/quem-somos" activeClassName="active">
              Quem Somos
            </Link>
          </li>
          <li>
            <Link to="/pt/contato" activeClassName="active">
              Contato
            </Link>
          </li>
        </ul>

        <div className="social-icons">
          <a
            href="https://historiaislamica.com.br/feed/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faRss} />
          </a>
          <a
            href="https://www.facebook.com/historiaislamica/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.instagram.com/historiaislamicaoficial/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://open.spotify.com/show/3BY8at4LL2JrRlqCHYu7xU"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSpotify} />
          </a>
          <a
            href="https://t.me/historiaislamica"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} />
          </a>
          <a
            href="http://apoia.se/historia-islamica"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faMoneyBill} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC48ipESG1nGIwp18AofJDzQ"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default MobileNav
