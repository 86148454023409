import React, { useRef, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRss,
  faMoneyBill,
  faSearch,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faInstagram,
  faSpotify,
  faTelegram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

import logo from '../../images/logo.png'

import './styles.css'

const Header = () => {
  const queryInput = useRef(null)

  const [langToggle, setLangToggle] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    navigate(`/pt/search/?s=${queryInput.current.value}`, {
      state: { query: queryInput.current.value },
      replace: true,
    })
  }

  const handleToggleLang = () => {
    setLangToggle(!langToggle)
  }

  return (
    <header>
      <div className="top-header">
        <div className="social-icons">
          <a
            href="https://historiaislamica.com.br/feed/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faRss} />
          </a>
          <a
            href="https://www.facebook.com/historiaislamica/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://www.instagram.com/historiaislamicaoficial/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://open.spotify.com/show/3BY8at4LL2JrRlqCHYu7xU"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faSpotify} />
          </a>
          <a
            href="https://t.me/historiaislamica"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} />
          </a>
          <a
            href="http://apoia.se/historia-islamica"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faMoneyBill} />
          </a>
          <a
            href="https://www.youtube.com/channel/UC48ipESG1nGIwp18AofJDzQ"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </div>

        <Link to="/pt" className="logo">
          <img src={logo} alt="História Islâmica" />
        </Link>

        <div className="search-form">
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Procurar por..." ref={queryInput} />
            <button type="submit">
              <FontAwesomeIcon icon={faSearch} color="#b39fac" />
            </button>
          </form>
        </div>

        <button className="lang-selection-btn" onClick={handleToggleLang}>
          <FontAwesomeIcon icon={faGlobe} />
        </button>

        {langToggle && (
          <div className="lang-selection">
            <ul>
              <li>
                <Link activeClassName="active" to="/pt" partiallyActive={true}>
                  Português
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/en" partiallyActive={true}>
                  English
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
