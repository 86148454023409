import React, { useEffect } from 'react'
import { Link } from 'gatsby'

import './styles.css'

const PostCarousel = ({ posts, lang }) => {
  const setCurrentSlide = (event, id) => {
    if (typeof document === 'undefined') return

    const slides = document.querySelectorAll('.post-carousel-container')
    slides.forEach((slide) => {
      slide.style.opacity = 0
      slide.querySelector('a').style.zIndex = 0
    })

    const slide = document.getElementById(id)
    slide.style.opacity = 1
    slide.querySelector('a').style.zIndex = 1

    const thumbs = document.querySelectorAll('.post-carousel-thumbnails-img')
    thumbs.forEach((slide) => (slide.style.border = '3px solid transparent'))

    const thumb = event.target
    thumb.style.border = '3px solid #fff'
  }

  const setInitialSlide = () => {
    if (typeof document === 'undefined') return

    const slides = document.querySelectorAll('.post-carousel-container')
    const s = slides[0]
    s.style.opacity = 1
    s.querySelector('a').style.zIndex = 1

    const thumbs = document.querySelectorAll('.post-carousel-thumbnails-img')
    const t = thumbs[0]
    t.style.border = '3px solid #fff'
  }

  useEffect(() => {
    setInitialSlide()
  })

  return (
    <div className="post-carousel">
      {posts.map((post) => (
        <div
          className={`post-carousel-container`}
          id={post.slug}
          style={{
            backgroundImage: `linear-gradient(rgba(245, 246, 252, 0.52), rgb(0 0 0 / 93%)), url(${post.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="post-carousel-content">
            <span>{lang === 'pt' ? 'DESTAQUES' : 'FEATURED'}</span>
            <h2>{post.name}</h2>
            <Link to={`/${lang}/${post.slug}`}>
              {lang === 'pt' ? 'LER ARTIGO' : 'READ ARTICLE'}
            </Link>
          </div>
        </div>
      ))}

      <div className="post-carousel-thumbnails">
        {posts.map((post) => (
          <div
            className="post-carousel-thumbnails-img"
            onClick={(e) => setCurrentSlide(e, post.slug)}
            style={{
              width: 120,
              height: 80,
              backgroundImage: `url(${post.imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default PostCarousel
