import React from 'react'
import { Link } from 'gatsby'

import MadeWithIcon from '../../images/icons/MadeWith'

import './styles.css'

const Footer = () => {
  return (
    <footer>
      <div className="left-bar">
        <p>
          © 2020 História Islâmica | Feito por amor a{' '}
          <MadeWithIcon width={27} /> pela Taha Brasil
        </p>
      </div>

      <div className="right-bar">
        <nav>
          <ul>
            <li>
              <Link to="/pt">Início</Link>
            </li>
            <li>
              <Link to="/pt/tags">Tags</Link>
            </li>
            <li>
              <Link to="/pt/quem-somos">Quem Somos</Link>
            </li>
            <li>
              <Link to="/pt/politica-de-privacidade">
                Política de Privacidade
              </Link>
            </li>
            <li>
              <Link to="/pt/contato">Contato</Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default Footer
