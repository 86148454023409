import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import PostCarousel from '../../components/PostCarousel'
import PostList from '../../components/PostList'
import SEO from '../../components/SEO'

const IndexPage = ({ data }) => {
  const posts = [...data.allPostsOnePt.edges, ...data.allPostsTwoPt.edges]
  const sliderPosts = data.allSliderPt.edges[0]?.node?.posts

  return (
    <Layout>
      <SEO
        title="Home"
        canonical={`${process.env.GATSBY_HOST_URL}/pt`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en`}
      />

      <div>
        <PostCarousel posts={sliderPosts.slice(0, 3)} lang="pt" />

        <PostList posts={posts} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allPostsOnePt(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          name
          slug
          excerpt
          date(formatString: "DD/MM/YYYY", locale: "pt-BR")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }

    allPostsTwoPt(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          name
          slug
          excerpt
          date(formatString: "DD/MM/YYYY", locale: "pt-BR")
          imageUrl
          language {
            slug
          }
          meta {
            content
          }
        }
      }
    }

    allSliderPt {
      edges {
        node {
          posts {
            name
            slug
            imageUrl
          }
        }
      }
    }
  }
`
