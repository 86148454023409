import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import bannerCursos from '../../images/banner-cursos.jpeg'
import bannerPix from '../../images/banner-pix.jpeg'
import bannerYoutube from '../../images/banner-youtube-pt.jpeg'

import './styles.css'

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query allMonthPopularPt {
      allMonthPopularPt {
        edges {
          node {
            posts {
              id
              name
              slug
            }
          }
        }
      }
    }
  `)

  const posts = data.allMonthPopularPt?.edges[0]?.node?.posts

  return (
    <aside className="sidebar">
      <div className="apoiase banner-pix">
        <img
          src={bannerPix}
          alt="Ajude na continuidade da página História Islâmica"
        />
      </div>

      <div className="apoiase">
        <a
          href="https://historiaislamica.digital/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bannerCursos} alt="Apoie nosso projeto!" />
        </a>
      </div>

      <div className="apoiase banner-youtube">
        <a
          href="https://www.youtube.com/channel/UC48ipESG1nGIwp18AofJDzQ"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={bannerYoutube}
            alt="Adquira camisas do História Islâmica!"
          />
        </a>
      </div>

      <div className="most-read">
        <div className="most-read-header">
          <h3>Mais acessados</h3>
          <hr />
        </div>

        <div className="most-read-list">
          <ul>
            {posts.map((post) => (
              <li key={post.id}>
                <Link to={`/pt/${post.slug}`}>{post.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
